// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import colors from 'vuetify/lib/util/colors'

// Vuetify
import { createVuetify } from 'vuetify'

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: colors.grey.lighten5,
    secondary: '#4285F4',
    base: colors.grey.darken2,
    error: '#DB4437',
    info: '#F4B400',
    success: '#0F9D58',
    warning: '#F4B400',
  }
}

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    }
  },
  font: {
    family: 'Libre Baskerville'
  }
})
