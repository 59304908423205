import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/main'

const routes = [
    { 
        path: '/',
        name: 'Home',
        component: () => import('../views/home-view.vue')
    },
    {
      path: '/business-advisory/:advisoryId',
      name: 'Business Advisory Detail',
      component: () => import('../views/business-advisory-view.vue')
    },
    { 
      path: '/itinerary',
      name: 'Itinerary',
      component: () => import('../views/advisory-itinerary-view.vue'),
      meta: {
        authRequired: true
      }
    },
    { 
      path: '/googler-dashboard',
      name: 'Googler Dashboard',
      component: () => import('../views/googler-dashboard-view.vue'),
      meta: {
        authRequired: true,
        googlerView: true
      }
    },
    { 
      path: '/googler-advisory-dashboard/:advisoryId',
      name: 'Googler Advisory Dashboard',
      component: () => import('../views/googler-advisory-dashboard-view.vue'),
      meta: {
        authRequired: true,
        googlerView: true
      }
    }
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (auth.currentUser === null) {
      next('/')
    } else {
      if (to.matched.some(record => record.meta.googlerView)) {
          if(auth.currentUser.email.substring(auth.currentUser.email.indexOf("@") + 1) === 'google.com'
            && auth.currentUser.emailVerified) {
            next()
          } else {
            next('/')
          }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router