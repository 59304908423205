<template>
    <v-dialog v-model="dialog" width="600" persistent>
    <v-card height="600px">
        <v-tabs v-model="tab" height="80" class="bg-secondary"> 
            <v-tab value="sign-up">Sign up</v-tab>
            <v-tab value="login">Login</v-tab>
        </v-tabs>
        <v-card-text>
            <v-window v-model="tab">
                <v-window-item value="sign-up">
                    <signup-form @hideDialogChild="hideDialog()"></signup-form>
                </v-window-item>
                <v-window-item value="login">
                    <login-form  @hideDialogChild="hideDialog()"></login-form>
                </v-window-item>
            </v-window>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script setup>
import SignupForm from '@/components/forms/signup-form'
import LoginForm from '@/components/forms/login-form'
import { ref, defineProps, toRefs, defineEmits } from "vue";

const tab = ref("one")

const props = defineProps({
  dialog: Boolean
})

const emit = defineEmits(['hideDialog'])

function hideDialog() {
    tab.value = "one"
    emit('hideDialog')
}

const { dialog } = toRefs(props);

</script>
  