<template>
  <v-app>
    <v-app-bar color="primary" max-height="60">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-subtitle-1 font-weight-black">Generative AI Live | 
        <span class="text-secondary">Chile</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          v-if="!isAuthUser"
          @click="openLoginForm"
          color="secondary"
          dark
          rounded
          class="ml-5"
      >
      <v-icon right color="secondary" class="pr-3">mdi-account</v-icon>
          Login
      </v-btn>

      <v-btn v-else
          @click="logout"
          color="secondary"
          dark
          rounded
          class="ml-5"
      >
      <v-icon right color="secondary" class="pr-3">mdi-account</v-icon>
          Logout
      </v-btn>
      
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed app>
      <div class="pa-5 pb-0">
        <h3 text-subtitle-1 font-weight-black>Generative AI Live</h3>
        <h5 class="text-subtitle-1 font-weight-black text-secondary">Chile</h5>
      </div>
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="$router.push(item.view)"
        >
          <template v-slot:prepend>
            <v-icon :icon="item.icon" class="mr-2"></v-icon>
          </template>
          <v-list-item-title>{{item.title}}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense nav v-if="isAuthUser">
        <v-list-item
          v-for="item in authItems"
          :key="item.title"
          @click="$router.push(item.view)"
        >
          <template v-slot:prepend>
            <v-icon :icon="item.icon" class="mr-2"></v-icon>
          </template>
          <v-list-item-title>{{item.title}}</v-list-item-title>
        </v-list-item>
      </v-list>
      <div v-if="isGoogler()">
        <v-divider></v-divider>
          <v-list dense nav >
            <v-list-item
              v-for="item in internalItems"
              :key="item.title"
              @click="$router.push(item.view)"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon" class="mr-2"></v-icon>
              </template>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
          </v-list>
      </div>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
    <section id="footer" class="footer">
      <v-container>
        <h3 class="mb-5 text-base">Google Cloud Chile</h3>
      </v-container>
    </section>
    <login-dialog :dialog="dialog" @hideDialog="dialog = false"/>
  </v-app>
</template>

<script>
import { auth } from '@/main'
import LoginDialog from '@/components/dialogs/login-dialog'
import { signOut } from "firebase/auth"
import router from "@/router"


export default {
  name: 'App',
  components: {
    LoginDialog
  },
  data: () => ({
    drawer: false,
    isAuthUser: auth.currentUser,
    dialog:false,
    displayName: auth.currentUser ? auth.currentUser.displayName : "",
    // uid: auth.currentUser.uid,
    items: [
      { title: 'Home', icon: 'mdi-home', view: '/' }
    ],
    authItems: [
      { title: 'Itinerario de Advisories', icon: 'mdi-note', view: '/itinerary' }
    ],
    internalItems: [
      { title: 'Googler Dashboard', icon: 'mdi-google', view: '/googler-dashboard' }
    ]
  }),
  methods: {
      openLoginForm () {
        this.dialog = true;
      },
      logout () {
        signOut(auth).then(() => {
          router.go()
        }).catch((error) => {
          console.log(error)
        });
      },
      isGoogler() {
        if(this.isAuthUser) {
          return (auth.currentUser.email.substring(auth.currentUser.email.indexOf("@") + 1) === 'google.com' ? true : false)
        }
      }
    },
}
</script>

<style>
  @font-face {
    font-family: "GoogleSans-Regular";
    src:
      url('../static/fonts/GoogleSans/GoogleSans-Regular.ttf')  format('truetype')
  }

  #app {
    font-family: GoogleSans-Regular;
  }

  .bg-agenda-row {
    background-color: #EDEDED;
  }

  .bg-light {
    background-color: #FAFAFA;
  }

  .footer {
    margin-top: 50px;
    padding-top: 50px;
    background-color: #FAFAFA;
  }


</style>
