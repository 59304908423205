<template>
  <div>
    <p>Crea una nueva cuenta.</p>
    <form @submit.prevent="submit">
      <v-card-text class="pl-0">
        <v-text-field
          v-model="name.value.value"
          variant="underlined"
          :error-messages="name.errorMessage.value"
          label="Nombre Completo"
        ></v-text-field>

        <v-text-field
          v-model="email.value.value"
          variant="underlined"
          :error-messages="email.errorMessage.value"
          label="E-mail"
        ></v-text-field>

        <v-text-field
          v-model="password.value.value"
          type="password"
          variant="underlined"
          :error-messages="password.errorMessage.value"
          label="Contraseña"
        ></v-text-field>

        <v-text-field
          v-model="company.value.value"
          variant="underlined"
          :error-messages="company.errorMessage.value"
          label="Compañia"
        ></v-text-field>

        <v-text-field
          v-model="role.value.value"
          variant="underlined"
          :error-messages="role.errorMessage.value"
          label="Rol (e.j. CTO o Dir. de Infraestructura)"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="primary mb-5">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            text
            class="ml-5 mr-5"
            @click="
              handleReset();
              $emit('hideDialogChild');
            "
          >
            Cerrar
          </v-btn>
          <v-btn color="secondary" class="me-4" type="submit">submit</v-btn>
        </div>
      </v-card-actions>
    </form>
  </div>
</template>

<script setup>
import { useField, useForm } from 'vee-validate'
import { auth } from '@/main'
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth"
import router from "@/router"
import { firestore } from '@/main'
import { setDoc, doc } from "firebase/firestore"

const { handleSubmit, handleReset } = useForm({
        validationSchema: {
          name (value) {
            if (value?.length >= 5) return true

            return 'Nombre debe contar con al menos 5 caractéres.'
          },
          email (value) {
            if (/^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-z]+$/i.test(value)) return true

            return 'Must be a valid e-mail.'
          },
          password (value) {
            if (value?.length >= 8) return true

            return 'La contraseña debe contar con al menos 8 caractéres.'
          },
          company (value) {
            if (value?.length >= 1) return true

            return 'El campo es requerido.'
          },
          role (value) {
            if (value?.length >= 1) return true

            return 'El campo es requerido.'
          },
        },
      })

      const name = useField('name')
      const email = useField('email')
      const company = useField('company')
      const role = useField('role')
      const password = useField('password')


      const submit = handleSubmit(values => {
        createUserWithEmailAndPassword(auth, values.email, values.password)
            .then((userCredential) => {
                const user = userCredential.user;
                const userProfile = {
                  uid: user.uid,
                  fullName: values.name,
                  company: values.company,
                  role: values.role
                }

                setDoc(doc(firestore, "users", user.uid), userProfile).then(() => {
                  const actionCodeSettings = {
                    url: 'https://generative.cloud-experience.xyz/',
                  }
                  sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => {
                    router.go()
                  })
                })
            })
            .catch((error) => {
                console.log(error)
            })
      })
</script>