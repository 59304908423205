<template>
  <div>
    <p>Si ya cuentas con una cuenta, registrate.</p>
    <form @submit.prevent="submit">
      <v-card-text class="pa-0 pt-5">
        <v-text-field
          v-model="email.value.value"
          variant="underlined"
          :error-messages="email.errorMessage.value"
          label="E-mail"
        ></v-text-field>

        <v-text-field
          v-model="password.value.value"
          type="password"
          variant="underlined"
          :error-messages="password.errorMessage.value"
          label="Contraseña"
        ></v-text-field>
      </v-card-text>
      <v-card-text if="authError" class="pa-0">
        <span class="text-base small-font">{{ errorMessage }}</span>
      </v-card-text>
      <v-card-actions class="primary mb-5">
        <v-spacer></v-spacer>
        <div>
          <v-btn
            text
            class="ml-5 mr-5"
            @click="
              handleReset();
              $emit('hideDialogChild');
            "
          >
            Cerrar
          </v-btn>
          <v-btn color="secondary" class="me-4" type="submit">submit</v-btn>
        </div>
      </v-card-actions>
    </form>    
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import { auth } from '@/main'
import router from "@/router"
import { signInWithEmailAndPassword } from "firebase/auth"

const errorMessage = ref('')
const authError = ref(false)

const { handleSubmit, handleReset } = useForm({
        validationSchema: {
          email (value) {
            if (/^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-z]+$/i.test(value)) return true

            return 'Debes contar con un e-mail válido.'
          },
          password (value) {
            if (value?.length >= 8) return true

            return 'La contraseña debe contar con al menos 8 caractéres.'
          }
        },
      })

      const email = useField('email')
      const password = useField('password')


      const submit = handleSubmit(values => {
        signInWithEmailAndPassword(auth, values.email, values.password)
            .then((userCredential) => {
                console.log(userCredential.user)
                router.go()
            })
            .catch((error) => {
              if(error.code == 'auth/wrong-password') {
                errorMessage.value = 'La contraseña no es válida.'
                authError.value = true
              } else if (error.code == 'auth/user-not-found') {
                errorMessage.value = 'No se ha encontrado un usuario en el sistema con ese email.'
                authError.value = true
              }
        })
      })
</script>